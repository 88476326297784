import PropTypes from "prop-types";
import styles from "../Policy.module.css";

const Policies = ({ policyTitle, policySubTitle }) => {
  return (
    <div className={`${styles.policiesbcg}`}>
      <h1>{policyTitle}</h1>
      <p>{policySubTitle}</p>
    </div>
  );
};
Policies.PropTypes = {
  policyTitle: PropTypes.string,
  policySubTitle: PropTypes.string
};

export default Policies;

/* eslint-disable max-len */
import styles from "./Faq.module.css";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const index = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.contmargin}`}>
        <div className={`${styles.faqheader}`}>
          <h3>Frequently Asked Questions</h3>
        </div>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          sx={{
            boxShadow: "none",
            "@media (max-width: 430px)": { paddingBlock: "5px" },
            "&.Mui-expanded": {
              backgroundColor: "#E6F0FF",
              borderRadius: "20px",
              paddingBlock: "16px",
              transition: "0.6s",
              fontWeight: "500",
              "@media (max-width: 430px)": { paddingBlock: "5px" }
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{ alignItems: "center" }}
          >
            <div className={`${styles.questionmark}`}>
              <HelpOutlineIcon />
            </div>
            <h4 className={`${styles.question} margin-top-10`}>
            What is Shelf21
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className={`${styles.answers}`}>
            Shelf21 is a complete school management software that makes it easy to handle administrative tasks, improve communication, and simplify daily operations for schools.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          sx={{
            boxShadow: "none",
            "@media (max-width: 430px)": { paddingBlock: "5px" },
            "&.Mui-expanded": {
              backgroundColor: "#E6F0FF",
              borderRadius: "20px",
              paddingBlock: "16px",
              transition: "0.6s"
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <div className={`${styles.questionmark}`}>
              <HelpOutlineIcon />
            </div>
            <h4 className={`${styles.question} margin-top-10`}>
            How can I get started?
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className={`${styles.answers}`}>
            Getting started with Shelf21 is simple. Sign up as a school admin, add your teachers, students, and parents information, and begin managing your school with an all-in-one, user-friendly system
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          sx={{
            boxShadow: "none",
            "@media (max-width: 430px)": { paddingBlock: "5px" },
            "&.Mui-expanded": {
              backgroundColor: "#E6F0FF",
              borderRadius: "20px",
              paddingBlock: "16px",
              transition: "0.6s"
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <div className={`${styles.questionmark}`}>
              <HelpOutlineIcon />
            </div>
            <h4 className={`${styles.question} margin-top-10 margin-inline-24`}>
            What’s the pricing like?
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className={`${styles.answers}`}>
            There is a free version that covers basic needs of a school, premium and enterprise pricing is in the works for big schools that needs more advanced features.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          sx={{
            boxShadow: "none",
            "@media (max-width: 430px)": { paddingBlock: "5px" },
            "&.Mui-expanded": {
              backgroundColor: "#E6F0FF",
              borderRadius: "20px",
              paddingBlock: "16px",
              transition: "0.6s"
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <div className={`${styles.questionmark}`}>
              <HelpOutlineIcon />
            </div>
            <h4 className={`${styles.question} margin-top-10 margin-inline-24`}>
            What are these features about?.
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className={`${styles.answers}`}>
            The features on Shelf21 are quite extensive, that is why we have created a guide(link to Youtube page) for easy reference.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
          sx={{
            boxShadow: "none",
            "@media (max-width: 430px)": { paddingBlock: "5px" },
            "&.Mui-expanded": {
              backgroundColor: "#E6F0FF",
              borderRadius: "20px",
              paddingBlock: "16px",
              transition: "0.6s"
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5bh-content"
            id="panel5bh-header"
          >
            <div className={`${styles.questionmark}`}>
              <HelpOutlineIcon />
            </div>
            <h4 className={`${styles.question} margin-top-10 margin-inline-24`}>
            Can the software be accessed on different devices?
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className={`${styles.answers}`}>
            Administrators and teachers can access the software on both laptops and phones. Students, however, are limited to phone access.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
          sx={{
            boxShadow: "none",
            "@media (max-width: 430px)": { paddingBlock: "5px" },
            "&.Mui-expanded": {
              backgroundColor: "#E6F0FF",
              borderRadius: "20px",
              paddingBlock: "16px",
              transition: "0.6s"
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6bh-content"
            id="panel6bh-header"
          >
            <div className={`${styles.questionmark}`}>
              <HelpOutlineIcon />
            </div>
            <h4 className={`${styles.question} margin-top-10 margin-inline-24`}>
            How secured is the data stored in Shelf21?
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className={`${styles.answers}`}>
            Shelf21 ensures your data is highly secure with encryption and regular backups, keeping all school information safe and protected. (Needs revision)

            </p>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default index;

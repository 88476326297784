import Header from "../../components/Header";
import Footer from "../../components/Footer/index";
import styles from "./ReleaseNote.module.css";
import { Link } from "react-router-dom";
import ReleaseUpdate from "./component/ReleaseUpdate";
import ReleaseUpdateImg from "./../../assets/images/blogthumbnail.jpg";

const ReleaseNote = () => {
  return (
    <div>
      <Header />
      <div className={`${styles.containerbcg}`}>
        <div className={`${styles.container}`}>
          <div className={`${styles.contmargin}`}>
            <div className={`${styles.releaseheader}`}>
              <h1>See the releases and Updates available to you.</h1>
              <p>
              Empowering Schools, Engaging Students, and Connecting Parents, all
              in one platform.
              </p>
              <div className={`${styles.releaseupdate}`}>
                <p>Lasted release was updated 23rd Mar, 2024.</p>
                <span><Link>Click to see release.</Link></span>
              </div>
            </div>
            <ReleaseUpdate
              updateimg={ReleaseUpdateImg}
              updatetitle="Attendance Tracking"
              updatedesc="Saving time and reducing administrative workload.Creation of a free full
             functional account allows you to add your own people and data and will qualify you as
             data controller for the processing activities that you carry out. We have no control,
             and we are not responsible for the processing activities that you perform over the personal
             data you process within the account."
            />
            <ReleaseUpdate
              updateimg={ReleaseUpdateImg}
              updatetitle="Attendance Tracking"
              updatedesc="Saving time and reducing administrative workload.Creation of a free full
             functional account allows you to add your own people and data and will qualify you as
             data controller for the processing activities that you carry out. We have no control,
             and we are not responsible for the processing activities that you perform over the personal
             data you process within the account."
            />
            <ReleaseUpdate
              updateimg={ReleaseUpdateImg}
              updatetitle="Attendance Tracking"
              updatedesc="Saving time and reducing administrative workload.Creation of a free full
             functional account allows you to add your own people and data and will qualify you as
             data controller for the processing activities that you carry out. We have no control,
             and we are not responsible for the processing activities that you perform over the personal
             data you process within the account."
            />
            <ReleaseUpdate
              updateimg={ReleaseUpdateImg}
              updatetitle="Ensure the highest level of care for staff, students and guardians."
              updatedesc="Saving time and reducing administrative workload.Creation of a free full
             functional account allows you to add your own people and data and will qualify you as
             data controller for the processing activities that you carry out. We have no control,
             and we are not responsible for the processing activities that you perform over the personal
             data you process within the account."
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ReleaseNote;

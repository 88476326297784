import styles from "./ErrorPage.module.css";
import ButtonMd from "../Button/ButtonMd";
import PropTypes from "prop-types";

const NoRequestAccess = ({ errimg, errtitle, errdesc, errrequest }) => {
  return (
    <>
      <div className={`${styles.norequestmodal}`}>
        <img className={`${styles.errimg}`} src={errimg} alt="" width="100%" />
        <h4>{errtitle}</h4>
        <p className={`${styles.errdescription}`}>{errdesc}</p>
        <ButtonMd title={errrequest} />
      </div>
    </>
  );
};
NoRequestAccess.Prototype = {
  errimg: PropTypes.any,
  errtitle: PropTypes.string,
  errdesc: PropTypes.string,
  errrequest: PropTypes.string
};

export default NoRequestAccess;

import Header from "../../components/Header";
import Footer from "../../components/Footer/index";
import MajorFeature from "./component/MajorFeature";
import OtherFeatures from "./component/OtherFeatures";

const UpdateInfomation = () => {
  return (
    <div>
      <Header />
      <MajorFeature
        versiontitle="Shelf 21 Version 1.2.13"
        releasedate="13 Mar. 2024"
        versiondesc="Record and report on safeguarding information
         including background checks, and safer recruitment trainingRecord
          and report on safeguarding information including background checks,
           and safer recruitment training"
        majorfttitle="Attendance Tracking"
        majorftdesc="Keep track of student attendance easily and efficiently,
         saving time and reducing administrative workload. Keep track of student
          attendance easily and efficiently, saving time and reducing administrative
           workload. Keep track of student attendance easily and efficiently, saving time
            and reducing administrative workload. Keep track of student attendance easily and
             efficiently, saving time and reducing administrative workload."
        majorftsubtitle="Key Sub Features"
      />
      <OtherFeatures otherftsubtitle="1. Key Sub features" otherftsubtitletwo="2. Key Sub features" />
      <Footer />
    </div>
  );
};

export default UpdateInfomation;

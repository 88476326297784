import styles from "./Feature.module.css";
import PropTypes from "prop-types";
import CircleIcon from "@mui/icons-material/Circle";
import MajorFeatureImage from "../../../assets/images/featureimage.png";

const OtherFeatures = ({ otherftsubtitle, otherftsubtitletwo }) => {
  return (
    <div>
      <div className={`${styles.container}`}>
        <div className={`${styles.otherfeaturecont}`}>
          <h4 className={`${styles.otherfeature}`}>Improvements top feature</h4>
          <div className={`${styles.otherfeaturecontent} margin-inline-24`}>
            <h5>{otherftsubtitle}</h5>
            <div className={`${styles.subfeaturecont}`}>
              <p>Here are some of the perks you get to enjoy with this feature.</p>
              <div className="flex gap-8 margin-top-20">
                <div className={`${styles.circleforparagraph}`}>
                  <CircleIcon sx={{ height: "6px", color: "black" }} />
                </div>
                <p> Keep track of student attendance easily and efficiently,
                     saving time and reducing administrative workload. Keep
                      track of student attendance easily and efficiently, saving
                       time and reducing administrative workload.
                </p>
              </div>
              <div>
                <img src={MajorFeatureImage} alt="" width="100%" />
              </div>
            </div>
          </div>
          <div className={`${styles.otherfeaturecontent} margin-inline-24`}>
            <h5>{otherftsubtitletwo}</h5>
            <div className={`${styles.subfeaturecont}`}>
              <p>Here are some of the perks you get to enjoy with this feature.</p>
              <div className="flex gap-8 margin-top-20">
                <div className={`${styles.circleforparagraph}`}>
                  <CircleIcon sx={{ height: "6px", color: "black" }} />
                </div>
                <p> Keep track of student attendance easily and efficiently,
                     saving time and reducing administrative workload. Keep
                      track of student attendance easily and efficiently, saving
                       time and reducing administrative workload.
                </p>
              </div>
              <div>
                <img src={MajorFeatureImage} alt="" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
OtherFeatures.PropTypes = {
  versiontitle: PropTypes.string,
  releasedate: PropTypes.string,
  versiondesc: PropTypes.string,
  majorftdesc: PropTypes.string,
  majorfttitle: PropTypes.string
};

export default OtherFeatures;

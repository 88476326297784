import styles from "./PriceCard.module.css";
import PropTypes from "prop-types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ButtonArr from "../../Button/ButtonArr";

const PriceCard = ({ amount, payType, planType, planDescription }) => {
  return (
    <div className={`${styles.pricemain}`}>
      <div className={`${styles.pricecont}`}>
        <h3>
          {amount}/{payType}
        </h3>
        <h5>{planType}</h5>
        <h6>{planDescription}</h6>
        <div className={`${styles.subdesc}`}>
          <div className={`${styles.featuredesc} flex gap-8 align-item-center`}>
            <CheckCircleIcon className="light-blue" />
            <p>Get daily student Attendance</p>
          </div>
          <div className={`${styles.featuredesc} flex gap-8 align-item-center`}>
            <CheckCircleIcon className="light-blue" />
            <p>Send Attendance report to parents</p>
          </div>
          <div className={`${styles.featuredesc} flex gap-8 align-item-center`}>
            <CheckCircleIcon className="light-blue" />
            <p>Overview of overall attendance record of students</p>
          </div>
          <div className={`${styles.featuredesc} flex gap-8 align-item-center`}>
            <CheckCircleIcon className="light-blue" />
            <p>Something along the lines of attendance still.</p>
          </div>
        </div>
        <ButtonArr title="Get Started" />
      </div>
    </div>
  );
};
PriceCard.PropTypes = {
  amount: PropTypes.string,
  payType: PropTypes.string,
  planType: PropTypes.string,
  planDescription: PropTypes.string
};

export default PriceCard;

import styles from "./Button.module.css";
import PropTypes from "prop-types";
import NorthEastIcon from "@mui/icons-material/NorthEast";

// eslint-disable-next-line react/prop-types
const ButtonMd = ({ title }) => {
  return (
    <div className={`${styles.btnmd} flex gap-8 justify-center`}>
      <p>{title}</p>
      <NorthEastIcon className="icon-white little-margin-top" />
    </div>
  );
};
ButtonMd.PropTypes = {
  title: PropTypes.string
};

export default ButtonMd;

import { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import styles from "./Header.module.css";

const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openSolutionDropdown, setOpenSolutionDropdown] = useState(false);
  const navigate = useNavigate();

  const handlePageClick = (page) => {
    switch (page) {
    case "Guide":
      window.open("https://www.youtube.com/playlist?list=PLNGFanEgGNga_3dxFS9AKr-HvkypE9a-l", "_blank");
      break;
    case "Features":
      navigate("/feature");
      break;
    default:
      break;
    }
    setOpenDrawer(false);
  };

  const handleSolutionClick = (subItem) => {
    if (subItem === "custom") {
      window.open("https://www.corpine.com/#contact", "_blank");
    } else if (subItem === "feature") {
      navigate("/feature-request");
    }
    setOpenDrawer(false);
  };

  return (
    <>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={{
          width: "100vw",
          height: "100vh",
          color: "white",
          "& .MuiDrawer-paper": {
            width: "100%",
            height: "100%",
            backgroundColor: "transparent",
            boxShadow: "none",
            backdropFilter: "blur(5px)"
          }
        }}
      >
        <IconButton
          onClick={() => setOpenDrawer(false)}
          sx={{ color: "white", position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon />
        </IconButton>
        <div style={{ marginBottom: "3rem" }}></div>
        <List>
          {/* Guide Item */}
          <ListItemButton onClick={() => handlePageClick("Guide")}>
            <ListItemIcon>
              <ListItemText sx={{ color: "white" }}>Guide</ListItemText>
            </ListItemIcon>
          </ListItemButton>

          {/* Solution Item with Dropdown */}
          <ListItemButton onClick={() => setOpenSolutionDropdown(!openSolutionDropdown)}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
              <ListItemIcon>
                <ListItemText sx={{ color: "white" }}>Solution</ListItemText>
              </ListItemIcon>
              {openSolutionDropdown ? <ExpandLess sx={{ color: "white" }} /> : <ExpandMore sx={{ color: "white" }} />}
            </div>
          </ListItemButton>

          <Collapse in={openSolutionDropdown} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }} onClick={() => handleSolutionClick("custom")}>
                <ListItemText sx={{ color: "white" }}>Custom Solutions</ListItemText>
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }} onClick={() => handleSolutionClick("feature")}>
                <ListItemText sx={{ color: "white" }}>Feature Request</ListItemText>
              </ListItemButton>
            </List>
          </Collapse>

          {/* Features Item */}
          <ListItemButton onClick={() => handlePageClick("Features")}>
            <ListItemIcon>
              <ListItemText sx={{ color: "white" }}>Features</ListItemText>
            </ListItemIcon>
          </ListItemButton>
        </List>
      </Drawer>

      <IconButton
        sx={{ color: "#000000", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon className={`${styles.menuicon}`} color="#000000" />
      </IconButton>
    </>
  );
};

export default DrawerComp;

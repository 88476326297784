import styles from "./FeatureComponent.module.css";
import PropTypes from "prop-types";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const AllFeatures = ({ title, featuretitle, featuredesc, backgroundColor, fontColor, features = [], image }) => {
  const contbcgcolor = {
    background: backgroundColor,
    border: `1px solid ${fontColor}`

  };
  const featurecolor = {
    color: fontColor
  };

  return (
    <div className={`${styles.forfeatures}`} style={contbcgcolor}>
      <div className={`${styles.featureleft}`}>
        <div className={`${styles.featureleftheader}`}>
          <h6 style={featurecolor}>{title}</h6>
          <h5>{featuretitle}</h5>
          <p className={`${styles.featuredesc}`}>{featuredesc}</p>
        </div>
        {features.map((description, index) => (
          <div className={`flex gap-16 ${index !== 0 ? "margin-top-25" : ""}`} key={index}>
            <div>
              <CheckCircleOutlineIcon />
            </div>
            <p>{description}</p>
          </div>
        ))}
      </div>
      <div className={`${styles.featureright}`}>
        <img src={image} alt="feature" />
      </div>
    </div>
  );
};
AllFeatures.PropTypes = {
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
  title: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  featuredesc: PropTypes.string

};

export default AllFeatures;

import Hero from "./Hero/index";
import AboutShelf from "./AboutShelf/index";
// import Documentation from "./ApiDoc/Documentation";
// import Pricing from "../../components/Pricing/Pricing";
import FAQ from "../../components/FAQ/index";

const index = () => {
  return (
    <>
      <Hero />
      <AboutShelf />
      {/* <Documentation /> */}
      {/* <Pricing /> */}
      <FAQ />
    </>
  );
};

export default index;

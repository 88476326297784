import styles from "./Button.module.css";
import PropTypes from "prop-types";

const ButtonLg = ({ title }) => {
  return (
    <div className={`${styles.btnlg}`}>
      <p>{title}</p>
    </div>
  );
};
ButtonLg.PropTypes = {
  title: PropTypes.string
};

export default ButtonLg;

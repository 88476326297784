import PropTypes from "prop-types";
import styles from "./Feature.module.css";

const Feature = ({ featureTitle, featureDescription, asset, type, featureicon }) => {
  return (
    <div className={`${styles.container}`}>
      <div
        className={`${styles.contmargin} flex align-item-center space-between flex-wrap`}
      >
        {type === "video" ? (
          <div className={`${styles.featureasset}`}>
            <video className={`${styles.featurevideo}`} loop autoPlay muted>
              <source src={asset} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
          </div>

        ) :
          <div className={`${styles.featureimage}`}>
            <img src={asset} alt="features image" width="100%" />
          </div>}
        <div className={`${styles.featureinfo}`}>
          <img src={featureicon} alt="feature Icon" />
          <h4>{featureTitle}</h4>
          <p>{featureDescription}</p>
        </div>
      </div>
    </div>
  );
};
Feature.PropTypes = {
  featureTitle: PropTypes.string,
  featureDescription: PropTypes.string,
  image: PropTypes.any,
  featureicon: PropTypes.any
};

export default Feature;

import styles from "../ReleaseNote.module.css";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";

const ReleaseUpdate = ({ updatetitle, updatedesc, updateimg }) => {
  return (
    <div className={`${styles.updatemaincont}`}>
      <Grid container spacing={2}>
        <Grid className={`${styles.updateleft}`} item lg={3} md={3} sm={6} xs={6}>
          <div className={`${styles.forupdateimg}`}>
            <img src={updateimg} alt="" />
          </div>
        </Grid>
        <Grid className={`${styles.updateright}`} item lg={9} md={9} sm={12} xs={12}>
          <div className={`${styles.releaseupdateinfo}`}>
            <h4>{updatetitle}</h4>
            <p>{updatedesc}</p>
            <Link to="/release-note/:id">See more details</Link>
          </div>
        </Grid>
      </Grid>
      <hr />
    </div>
  );
};
ReleaseUpdate.PropTypes = {
  updateimg: PropTypes.any,
  updatetitle: PropTypes.string,
  updatedesc: PropTypes.string
};

export default ReleaseUpdate;

import Header from "../../components/Header/index";
import Footer from "../../components/Footer/index";
import { Grid } from "@mui/material";
import InputGroup from "../../components/FormComponent/InputGroup";
import TextAreaGroup from "../../components/FormComponent/TextAreaGroup";
import styles from "./RequestPages.module.css";
// import RequestImage from "../../assets/images/requestfeature.png";
import ButtonLg from "../../components/Button/ButtonLg";
import NoRequestAccess from "../../components/ErrorPage/NoRequestAccess";
import { useEffect, useState } from "react";

const FeatureRequest = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("shelf21-auth");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <>
      <Header />
      <div className={`${styles.containerbcg}`}>
        <div className={`${styles.container}`}>
          <div className={`${styles.contmargin}`}>
            <div className={`${styles.requestheader}`}>
              <h3>Feature Request</h3>
              <p>
                What kind of feature would you like to see on the next version
                of our product? ople and data and will qualify you as data
                controller for the processing activities that you carry out.{" "}
              </p>
            </div>
            {isLoggedIn ? (
              <div className={`${styles.formarea}`}>
                <div className="flex gap-16 align-item-center">
                  <div className={`${styles.formleftcont}`}>
                    <Grid container rowGap={2} columnSpacing={2}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputGroup
                          inputType="text"
                          label="First Name"
                          placeHolder="Enter your First Name"
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputGroup
                          inputType="text"
                          label="Last Name"
                          placeHolder="Enter your Last Name"
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputGroup
                          inputType="email"
                          label="Email Address"
                          placeHolder="Enter your email Address"
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputGroup
                          inputType="text"
                          label="Phone Number"
                          placeHolder="Enter your Phone Number"
                        />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextAreaGroup
                          label="Custom Solution"
                          placeHolder="Custom solution"
                        />
                      </Grid>
                    </Grid>
                    <ButtonLg title="Request Custom Solution" />
                  </div>

                </div>
              </div>
            ) :
              null
            }
            <NoRequestAccess
              errtitle="Opps, you do not have access"
              errdesc="Sorry you do not have access to view this page seeing as you are not a school owner or a user in that category/class."
              errrequest="Return to home screen"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FeatureRequest;

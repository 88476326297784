import "./App.css";
import Header from "./components/Header/index";
import Home from "./pages/home/index";
import Footer from "./components/Footer/index";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <div className="">
      <ScrollToTop />
      <Header />
      <Home />
      <Footer />
    </div>
  );
}

export default App;
